import axios from 'axios'
import communLangage from '@/commun/commun.langage.js';

export default {
     name: 'Contact',

     components: {

     },

     data() {
          return {
               nomPrenom: null,
               email: null,
               sujet: null,
               message: null,
               fichier: null,

               estEnvoye: false,
               refresh: true,
               polling: null,

               apiUp: false,

               texte: {}
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          }
     },

     methods: {
          envoyer() {
               if (this.nomPrenom && this.sujet && this.email && this.message) {
                    axios.post('http://localhost:3000/email/sendEmail', {
                         from: this.email,
                         message: this.message,
                         subject: this.sujet,
                         nomPrenom: this.nomPrenom
                    });
                    this.estEnvoye = true;
                    this.refresh = true;
                    this.pollData();
               }
          },

          onChange(event) {
               this.fichier = event.target.files[0];
          },

          pollData() {
               this.polling = setInterval(() => {
                    this.refresh = false;
               }, 1000)
               this.polling = setInterval(() => {
                    this.estEnvoye = false;
               }, 3000)
          },

          testApiUp() {
               axios.get('http://localhost:3000/email/test', {

               })
                    .then((result) => {
                         if (result.data.ok == true) {
                              this.apiUp = true;
                         }
                         else {
                              this.apiUp = false;
                         }
                    });
          },

          copier(text) {
               navigator.clipboard.writeText(text);

          },

          changerText() {
               document.getElementById('title-email').textContent = "Copié !";
               setTimeout(() => { document.getElementById('title-email').textContent = "Copier l'email" }, 1500);
          },


          recupererTexte() {
               axios.get(process.env.BASE_URL + 'assets/textes/contact/contact.json').then(result => {
                    this.texte = this.langage == "fr" ? result.data.fr : result.data.en;
                    console.log(this.texte)

               });
          }
     },

     watch: {
          langage: function () {
               this.recupererTexte();
          }
     },

     beforeUnmount() {
          clearInterval(this.polling)
     },


     created() {
          console.log(this.texte)
          this.testApiUp();
          this.recupererTexte();
     }
}